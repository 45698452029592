<template>
	<div>
		<div class="container">
			<!--div class="sticky-button-ctn desktop">
				<StatusButton
					class="delete-button"
					type="primary"
					alert="modal"
					:enabled="check.length > 0"
					@click="showDeleteConfirm = $event"
				>{{ $t("common.dispute") }}</StatusButton>
			</div-->
			<h1 class="title-search">{{ $t("titles.reviews") }}</h1>
			<div class="filters">
				<div class="input-group form-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("common.sdate")}}</span>
					</div>
					<div ref="date1" id="date1" class="input-group date" data-target-input="nearest">
						<input class="form-control datetimepicker-input" data-target="#date1" name="date1" onkeydown="return false" type="text">
						<span class="input-group-append">
							<div v-if="filterDateStart" class="input-group-text btn btn-secondary" @click="filterDateStart = null; resetMinDate()">
								<i class="fa fa-times"></i>
							</div>
							<div class="input-group-text calendar-bg btn btn-secondary" data-target="#date1" data-toggle="datetimepicker">
								<i class="fa fa-calendar"></i>
							</div>
						</span>
					</div>
				</div>
				<div class="input-group form-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{$t("common.edate")}}</span>
					</div>
					<div ref="date2" id="date2" class="input-group date" data-target-input="nearest">
						<input class="form-control datetimepicker-input" data-target="#date2" name="date2" onkeydown="return false" type="text">
						<span class="input-group-append">
							<div v-if="filterDateEnd && filterDateEnd != dayjs().format('YYYY-MM-DD')" class="input-group-text btn btn-secondary" @click="filterDateEnd = null; resetMaxDate()">
								<i class="fa fa-times"></i>
							</div>
							<div class="input-group-text calendar-bg btn btn-secondary" data-target="#date2" data-toggle="datetimepicker">
								<i class="fa fa-calendar"></i>
							</div>
						</span>
					</div>
				</div>
			</div>
			<div id="notifications-list">
				<div>
					<div
						v-for="review in reviews"
						:key="review.id"
						class="notifs"
					>
						<div class="notif-content">
							<span class="date-notif">{{ $t( 'common.thedate' ) + ' ' + $d( new Date(review.date), "numeric" ) }}</span>
							<span class="bold"> {{ $t("common.by") }} </span>
							<span class="username">
							<router-link v-if="review.user && review.user.id > 0" :to="'/user?id=' + review.user.id">
								{{ review.user.fname + " " + review.user.lname }}
							</router-link>
							<i v-else-if="review.user">{{ review.user.fname }}</i>
							</span>
							:
							<span class="italic">«{{ review.text }}»</span>
						</div>

						<!--div class="checkbox-delete checkbox checkbox-primary desktop">
							<input
								:id="'reviews-checkbox-' + review.id"
								class="d-none"
								name="custom"
								type="checkbox"
								@change="checkReview(review)"
							/>
							<label class="d-inline-block" :for="'reviews-checkbox-' + review.id">
							</label>
						</div-->
						<button v-if="$store.state.worker.admin && review.date && dayjs(review.date).isAfter(dayjs().add(-7, 'day')) && !review.dispute_response" :disabled="!!review.dispute" class="btn btn-primary remove-review" @click="disputeReview=review">
							<!--i class="fas fa-trash-alt"></i-->
							<i v-if="!!review.dispute" class="far fa-hourglass"></i>
							<template v-else>
								{{ $t('common.dispute') }}
							</template>
						</button>
					</div>
				</div>
				<infinite-loading ref="loader" @infinite="infiniteHandler">
					<template v-slot:no-results>{{ $t('infiniteloader.noResult') }}</template>
					<template v-slot:no-more><span /></template>
					<template v-slot:error>{{ $t('infiniteloader.error') }}</template>
				</infinite-loading>
			</div>
		</div>
		<Confirm v-if="showDeleteConfirm" ref="deleteConfirm">
			{{ $t('reviews.confirmdelete') }}
		</Confirm>
		<DisputeReview v-if="disputeReview" @close="disputeReview=null" :review="disputeReview">
		</DisputeReview>
	</div>
</template>


<script>
	import dayjs from 'dayjs'
	import api from '../apiClient.js'
	import InfiniteLoading from 'vue-infinite-loading'
	import Confirm from "../modals/Confirm.vue"
	import StatusButton from '../components/StatusButton.vue'
	import DisputeReview from '../modals/DisputeReview.vue'
	
	export default {
		components: {
			Confirm,
			InfiniteLoading,
			StatusButton,
			DisputeReview
		},
		data() {
			return {
				dayjs,
				reviews: [],
				check: [],
				complete: false,
				showDeleteConfirm: false,
				filterDateStart: null,
				filterDateEnd: null,
				disputeReview: null
			}
		},
		watch: {
			showDeleteConfirm( callback ) {
				if ( callback !== false ) {
					this.$nextTick( () => {
						this.$refs.deleteConfirm.$on( "confirm", () => {
							this.$refs.deleteConfirm.$off( 'confirm' );
							this.$refs.deleteConfirm.$off( 'close' );
							this.showDeleteConfirm = false;
							this.deleteButton( callback );
						});
						this.$refs.deleteConfirm.$on( "close", () => {
							this.$refs.deleteConfirm.$off( 'confirm' );
							this.$refs.deleteConfirm.$off( 'close' );
							this.showDeleteConfirm = false;
							callback( -1 );
						});
					});
				}
			},
			filterDateStart() {
				this.reload()
			},
			filterDateEnd() {
				this.reload()
			},
			'$route'( to, from ) {
				if ( to.path == "/reviews" ) {
					// Reload infinite loader
					this.$nextTick( () => {
						this.reload()
						window.scrollTo( window.scrollX, window.scrollY - 1 )
						window.scrollTo( window.scrollX, window.scrollY + 1 )
					})
				}
			}
		},
		methods: {
			async deleteButton(setState) {
				await this.$api.reviews.delete({ '{or}': this.check.map(id => ({ id })) })
					.catch(e => setState(false, e.response.data.error))

				setState(true)
				this.check = []
			},
			checkReview({ id }) {
				const index = this.check.indexOf(id)
				if ( index >= 0 ) {
					this.check.splice(index)
				} else {
					this.check.push(id)
				}
			},
			infiniteHandler( infiniteState ) {
				this.update().then(newEntries => {
					if (!newEntries.length) {
						infiniteState.complete();
						this.complete = true;
					} else {
						infiniteState.loaded()
					}
				})
			},
			reload() {
				this.reviews = []
				this.$nextTick( () => {
					this.$refs.loader.stateChanger.reset()
				})
			},
			async update() {
				var params = new URLSearchParams()
				params.append( "{sort}", "desc:id" )
				if ( this.reviews.length > 0 ) params.append( "id[lt]", this.reviews[this.reviews.length - 1].id )
				if ( !this.filterDateStart || !this.filterDateEnd ) params.append( "{max}", 20 )
				if ( this.filterDateStart && this.filterDateStart.length > 0 ) params.append( "date[gte]", dayjs(this.filterDateStart).toISOString() )
				if ( this.filterDateEnd && this.filterDateEnd.length > 0 ) params.append( "date[lte]", dayjs(this.filterDateEnd).add(1, 'day').toISOString() )

				const response = await this.$api.reviews.get( params ).catch(e => console.error(e))

				console.log((response))

				const reviews = await Promise.all((response ? response.data : []) 
					.map(async ({ userid, ...fields }) => {
						const userRes = await this.$store.getters.getOnlineUser(userid)
						.catch(e => console.error(e))

						return {
							user: (userRes && userRes.data[0]) || {
								id: -1,
								fname: `[${this.$t('common.accdel')}]`,
								lname: ''
							},
							...fields
						}
					}))

				this.reviews.push(...reviews)
				return reviews
			},
			resetMinDate() {
				$(this.$refs.date1).find( "input" ).val( "" )
			},
			resetMaxDate() {
				$(this.$refs.date2).datetimepicker( "date", new Date() )
				this.filterDateEnd = dayjs().format( "YYYY-MM-DD" )
				$(this.$refs.date2).find( "input" ).val( dayjs().format("DD MMM YYYY") )
			}
		},
		mounted() {
			$(this.$refs.date1).datetimepicker({
				format: "DD MMM YYYY",
				maxDate: dayjs().format( "YYYY-MM-DD" ),
				widgetPositioning: { vertical: "bottom" },
				debug: ( process.env.VUE_APP_MODE === "development" ),
				icons: { "time": "fa fa-clock" },
				locale: this.$i18n.locale,
				useCurrent: false,
			})
			$(this.$refs.date1).on( "change.datetimepicker", (e) => {
				this.filterDateStart = e.date.format( "YYYY-MM-DD" )
			})

			$(this.$refs.date2).datetimepicker({
				format: "DD MMM YYYY",
				maxDate: dayjs().format( "YYYY-MM-DD" ),
				widgetPositioning: { vertical: "bottom" },
				debug: ( process.env.VUE_APP_MODE === "development" ),
				icons: { "time": "fa fa-clock" },
				locale: this.$i18n.locale,
				useCurrent: true
			})
			$(this.$refs.date2).on( "change.datetimepicker", (e) => {
				this.filterDateEnd = e.date.format( "YYYY-MM-DD" )
			})

			this.$api.reviews.watch( event => {
				console.log( event );
				if ( event.method == "POST" ) {
					if ( this.complete ) {
						Promise.all( event.data.map(async review => {
							const userRes = await this.$store.getters.getOnlineUser(review.userid).catch(e => console.error(e))
							this.reviews.push({
								user: (userRes && userRes.data[0]) || {
									id: -1,
									fname: `[${this.$t('common.accdel')}]`,
									lname: ''
								},
								...review
							})
						}))
					}
				} else if ( event.method == "PATCH" ) {
					event.data.forEach( review => {
						let inList = this.reviews.find( elem => elem.id == review.id );
						if ( inList ) {
							Object.assign( inList, review );
						}
					});
				} else if ( event.method == "DELETE" ) {
					event.data.forEach( review => {
						let inList = this.reviews.find( elem => elem.id == review.id );
						if ( inList ) {
							this.reviews.splice( this.reviews.indexOf( inList ), 1 );
						}
					});
				}
			});

			// Reload infinite loader
			this.$nextTick( () => {
				window.scrollTo( window.scrollX, window.scrollY - 1 )
				window.scrollTo( window.scrollX, window.scrollY + 1 )
			})
		}
	}
</script>


<style scoped lang="scss" src="../css/pages/reviews.scss"/>
